<template>
  <div class="" id="advice" @scroll="aaa" style="background: #393948; height: 553px;width: 100%;">
    <div style="height: -webkit-fill-available; display: flex; flex-direction: column; justify-content: center;">
      <span style="display: block;font-weight: 700;font-size: 24px;line-height: 29px;text-align: center;letter-spacing: -0.133571px;color: #FFFFFF">加密货币开创全新时代</span>
      <span style="display: block;font-weight: 500;font-size: 14px;line-height: 17px;text-align: center;letter-spacing: -0.133571px;color: rgba(255, 255, 255, 0.6);margin-top: 10px">U plus 助您創造、增值及保管財富</span>
      <el-carousel height="337" :interval="5000" ref="carousel" indicator-position="outside" arrow="never" trigger="click">
        <el-carousel-item v-for="(item, index) in contentList" :key="index">
          <div style="height: 337px;position: relative;display: flex; flex-direction: column; align-items: center; justify-content: center">
            <img style="width: 195px; height: 195px;" :src="item.img">
            <span style="display: block; font-weight: 500;font-size: 16px;line-height: 19px;text-align: center;letter-spacing: -0.133571px;color: #FFFFFF;margin-top: 10px">{{ item.info1 }}</span>
            <span style="display: block; font-weight: 500;font-size: 14px;line-height: 17px;text-align: center;letter-spacing: -0.133571px;color: rgba(255, 255, 255, 0.6);margin-top: 10px">{{ item.info2 }}</span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>
</template>

<script>
export default {
  name: "Home_advice_table",
  data(){
    return{
      contentList: [{
        img: require('../../../assets/img/home/three_one.png'),
        info1:'顶级性能，极速交易',
        info2:'交易所技术驱动，超低延迟，极速撮合',
      },{
        img: require('../../../assets/img/home/three_two.png'),
        info1:'资产保障，极致安全',
        info2:'U Plus 符合 CCSS 标准，保障资产及数据安全',
      },{
        img: require('../../../assets/img/home/three_three.png'),
        info1:'高流动，低点差',
        info2:'U Plus 合约杠杆交易，超高效率，最小滑点',
      },{
        img: require('../../../assets/img/home/three_four.png'),
        info1:'全天候服务',
        info2:'保证系统正常运行，提供全天候客户服务',
      },{
        img: require('../../../assets/img/home/three_five.png'),
        info1:'便捷易用',
        info2:'提供多种订单系统及交易工具，风险可控',
      },{
        img: require('../../../assets/img/home/three_six.png'),
        info1:'随时随地，安心交易',
        info2:'U Plus 平台提供PC网页版、手机 Android、iOS App',
      }],
      num: 1
    }
  },
  methods:{
    aaa(){
      // console.log(document.getElementById('advice').scrollLeft);
    }
  },
}
</script>

<style scoped>
th,td{
  border-right: #EAECEF solid 1px;
  border-bottom: #EAECEF solid 1px;
}
.advice{
  padding-top: 24px;
  padding-bottom: 24px;
}
.title{
  font-size: 20px;
  font-weight: 600;
  color: #000613;
  margin-bottom: 32px;
}
tr>th:nth-child(7),tr>td:nth-child(7){
  border-right: none;
}
tr>td:first-child{
  min-width: 100px;
}
tr>td:nth-child(2){
  padding: 21px 0 21px 16px;
  min-width: 150px;
}

tr>td:nth-child(3){
  padding-left: 18px;
  min-width: 110px;
}
tr>td:nth-child(4){
  padding-left: 18px;
  min-width: 120px;
}
tr>td:nth-child(5){
  padding-left: 16px;
  min-width: 150px;
}
tr>td:nth-child(6){
  padding-left: 18px;
  min-width: 110px;
}
tr>td:nth-child(7){
  padding-left: 18px;
  min-width: 50px;
}
tr>td:nth-child(3)>img:first-child{
  margin-right: 16px;
}
tr>td:nth-child(n+4){
  padding-left: 16px;
}
td:nth-child(7)>div{
  width: 40px;
  height: 40px;
  background: #AB54DB;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
}
th{
  font-size: 14px;
  font-weight: 500;
  color: #000613;
}
td{
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
}
/*操作th*/
th{
  text-align: left;
}
tr>th:nth-child(n+2){
  padding-left: 16px;
}

/*鼠标悬浮操作*/
tr>td:nth-child(7):hover,.content>div>span:hover{
  cursor: pointer;
  opacity: 0.9;
}

/*>>>.el-carousel__indicator--horizontal {*/
/*  display: inline-block;*/
/*  !* padding: 12px 4px; *!*/
/*  padding: unset;*/
/*  width: 6px;*/
/*  height: 6px;*/
/*  background: #97979D;*/
/*  border-radius: 50%;*/
/*  margin-left: 4px;*/
/*}*/
>>>.el-carousel__indicator.is-active button {
  display: inline-block;
  /* padding: 12px 4px; */
  padding: unset;
  width: 6px;
  height: 6px;
  background: #BF5FC1;
  border-radius: 50%;
  opacity: unset;
}
>>>.el-carousel__indicators--outside button {
  display: inline-block;
  /* padding: 12px 4px; */
  padding: unset;
  width: 6px;
  height: 6px;
  background: #97979D;
  border-radius: 50%;
  opacity: unset;
}

>>>.el-carousel__indicators--outside button :nth-child(2n+1) {
  margin-left: 4px;
}
</style>
