import request from "../utils/request";

/**
 * 获取全部公告接口
 * @returns {AxiosPromise}
 */
export const getNoticeMsgList=()=>request({
    method:'GET',
    url:'/msg/getNoticeMsgList'
})

/**
 * 获取公告详情
 * @param msgId
 * @returns {AxiosPromise}
 */
export const getNoticeMsgById = (msgId) => request({
    method: 'GET',
    url: '/msg/getNoticeMsgById',
    params:{msgId}
})
