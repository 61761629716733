<template>
  <div>
    <div v-if="!isMobile" class="service" style="height: 700px;width: 100%;">
      <div style="width: 1200px; display: flex; justify-content: space-between; align-items: center; height: -webkit-fill-available; margin: 0 auto;">
        <div class="title">用户遍布全球<br>100多个国家和地区</div>
        <div>
          <div>
            <div style="display: inline-block; width: 270px;cursor: pointer" @click="gotoProfit(1)">
              <span style="display: block; font-weight: 500;font-size: 16px;line-height: 24px;color: #FFFFFF;">全网累计收益(USDT) >></span>
              <span style="display: inline-block; font-weight: 500;font-size: 40px;line-height: 60px;color: #FFFFFF;">{{ data.profit | roundTwoFilter }}</span>
            </div>
            <div style="display: inline-block; width: 270px; margin-left: 20px;cursor: pointer" @click="gotoProfit(1)">
              <span style="display: block; font-weight: 500;font-size: 16px;line-height: 24px;color: #FFFFFF;">全网累计收益率 >></span>
              <span style="display: inline-block; font-weight: 500;font-size: 40px;line-height: 60px;color: #FFFFFF;">{{ data.profit_yield | roundTwoFilter }}%</span>
            </div>
          </div>
          <div style="margin-top: 110px">
            <div style="display: inline-block; width: 270px">
              <span style="display: block; font-weight: 500;font-size: 16px;line-height: 24px;color: #FFFFFF;">已稳定运营</span>
              <span style="display: inline-block; font-weight: 500;font-size: 40px;line-height: 60px;color: #FFFFFF;">{{ time }}天{{hour}}小时</span>
            </div>
            <div style="display: inline-block; width: 270px; margin-left: 20px">
              <span style="display: block; font-weight: 500;font-size: 16px;line-height: 24px;color: #FFFFFF;">机器人稳定性</span>
              <span style="display: inline-block; font-weight: 500;font-size: 40px;line-height: 60px;color: #FFFFFF;">99.999999%</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobile" class="service" style="height: 520px;width: 100%;">
      <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; height: -webkit-fill-available; margin: 0 auto;">
        <div class="title" style="font-weight: 500;font-size: 24px;line-height: 36px;text-align: center;color: #FFFFFF;width: 80%; margin: 0 auto">用户遍布全球100多个国家和地区</div>
        <div style="width: 90%;margin-top: 90px">
          <div style="display: flex; justify-content: space-between">
            <div style="display: inline-block; width: 48%" @click="gotoProfit(2)">
              <span style="display: block; font-weight: 500;font-size: 14px;line-height: 24px;color: #FFFFFF;">全网累计收益(USDT) >></span>
              <span style="display: inline-block; font-weight: 500;font-size: 24px;line-height: 36px;color: #FFFFFF;">{{ data.profit | roundTwoFilter }}</span>
            </div>
            <div style="display: inline-block; width: 48%;" @click="gotoProfit(2)">
              <span style="display: block; font-weight: 500;font-size: 14px;line-height: 24px;color: #FFFFFF;">全网累计收益率 >></span>
              <span style="display: inline-block; font-weight: 500;font-size: 24px;line-height: 36px;color: #FFFFFF;">{{ data.profit_yield | roundTwoFilter }}%</span>
            </div>
          </div>
          <div style="margin-top: 56px;display: flex; justify-content: space-between">
            <div style="display: inline-block; width: 48%">
              <span style="display: block; font-weight: 500;font-size: 14px;line-height: 24px;color: #FFFFFF;">已稳定运营</span>
              <span style="display: inline-block; font-weight: 500;font-size: 24px;line-height: 36px;color: #FFFFFF;">{{ time }}天{{hour}}小时</span>
            </div>
            <div style="display: inline-block; width: 48%;">
              <span style="display: block; font-weight: 500;font-size: 14px;line-height: 24px;color: #FFFFFF;">机器人稳定性</span>
              <span style="display: inline-block; font-weight: 500;font-size: 24px;line-height: 36px;color: #FFFFFF;">99.999999%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { pubInfo } from '../../server/home'
export default {
  name: "Home_service",
  data(){
    return{
      data: {},
      time: '',
      hour: '',
      isMobile: false
    }
  },

  created() {
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },

  mounted() {
    const lastTime = new Date("2021/03/18").getTime()
    this.time = parseInt((new Date().getTime() - lastTime)/(24*60*60*1000))
    this.hour = parseInt((new Date().getTime() - lastTime)%(24*60*60*1000)/(60*60*1000))
    this.pubInfo()
  },

  methods: {
    pubInfo(){
      pubInfo().then(res=>{
        if (!res) return
        if(res.success){
          this.data = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    gotoProfit(num){
      if(num == 1){
        window.open('https://uplus724.top/#/pages/index/allRank_pc')
      } else {
        window.open('https://uplus724.top/#/pages/index/allRank')
      }
    }
  }
}
</script>

<style scoped>
.service{
  background-image: url("../../assets/img/home/home_data_bg.png");
  background-size: cover;
}
.title{
  font-size: 40px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 72px;
}
.service>div:nth-child(2){
  width: 21.6%;
  height: 194px;
  display: inline-flex;
}
.service>div:nth-child(2)>img{
  width: 100%;
}
.contents{
  width: 73.6%;
  margin-left: 4.8%;
  display: inline-block;
  vertical-align: top;
}
.content>span{
  font-size: 16px;
  font-weight: 400;
  color: #000613;
  margin-left: 16px;
}
.content{
  padding: 20px 0;
  border-bottom: #EAECEF solid 1px;
  position: relative;
  display: flex;
  align-items: center;
}
.content>div{
  position: absolute;
  right: 0;
  top: 20px;
  width: 80px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #AB54DB;
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.content:first-child{
  border-top: #EAECEF solid 1px;
}

@media screen and (max-width: 900px){
  .service{
    background-image: url("../../assets/img/home/home_data_bg_mb.png");
    background-size: cover;
  }
  .title{
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 14px;
  }
  .service>div:nth-child(2){
    display: none;
  }
  .contents{
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 500px){
  .content>div{
    width: auto;
    height: 24px;
    border: none;
  }
  .content>div>span{
    display: none;
  }
}

/*处理鼠标悬浮*/
.content>div:hover{
  cursor: pointer;
  background: #AB54DB;
  color: #FFFFFF;
}
</style>
